import VueRouter from 'vue-router';
import { getAccessToken } from '@/utils/auth';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'user:list' }
  },
  {
    name: 'user',
    path: '/users',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'user:list' },
    meta: {
      label: 'Users'
    },
    children: [
      {
        path: '',
        name: 'user:list',
        component: () => import('../pages/user/UsersList.vue'),
        meta: {
          label: 'Users'
        }
      },
      {
        path: ':id',
        name: 'user:details',
        component: () => import('../pages/user/UserDetails.vue'),
        meta: {
          label: 'User Details'
        }
      },
      {
        path: ':id/edit',
        name: 'user:edit',
        component: () => import('../pages/user/UserUpdate.vue'),
        meta: {
          label: 'User Edit'
        }
      }
    ]
  },
  {
    name: 'auth',
    path: '/login',
    component: () => import('../layouts/AuthLayout.vue'),
    redirect: { name: 'auth:login' },
    children: [
      {
        path: '',
        name: 'auth:login',
        component: () => import('../pages/LoginPage/LoginPage.vue')
      }
    ]
  },
  {
    name: 'attention',
    path: '/attention',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'attention:user-duplicates' },
    meta: {
      label: 'Needs Attention'
    },
    children: [
      {
        path: 'userDuplicates',
        name: 'attention:user-duplicates',
        component: () => import('../pages/attention/UserDuplicates.vue'),
        meta: {
          label: 'User Duplicates'
        }
      },
      {
        path: 'invalidTransactions',
        name: 'attention:invalid-transactions',
        component: () => import('../pages/attention/InvalidTransactions.vue'),
        meta: {
          label: 'Invalid Transactions'
        }
      },
      {
        path: 'nonMatchingIban',
        name: 'attention:non-matching-iban',
        component: () => import('../pages/attention/NonMatchingIban.vue'),
        meta: {
          label: 'Non Matching IBAN'
        }
      },
      {
        path: 'wrongDetails',
        name: 'attention:wrong-details',
        component: () => import('../pages/attention/WrongDetails.vue'),
        meta: {
          label: 'Wrong Details'
        }
      },
      {
        path: 'rejectedApplications',
        name: 'attention:rejected-applications',
        component: () => import('../pages/attention/RejectedApplications/RejectedApplications.vue'),
        meta: {
          label: 'Rejected Applications'
        }
      }
    ]
  },
  {
    path: '/backoffice',
    name: 'backoffice',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'backoffice:employees-list' },
    meta: {
      label: 'Backoffice'
    },
    children: [
      {
        path: 'employees',
        name: 'backoffice:employees-list',
        component: () => import('../pages/backoffice/BackofficeEmployeesList.vue'),
        meta: {
          label: 'Employees'
        }
      },
      {
        path: 'employees/edit/:id',
        name: 'backoffice:employee-edit',
        component: () => import('../pages/backoffice/BackofficeEmployeeUpdate.vue'),
        meta: {
          label: 'Edit Employee'
        }
      },
      {
        path: 'employees/add',
        name: 'backoffice:employee-create',
        component: () => import('../pages/backoffice/BackofficeEmployeeCreate.vue'),
        meta: {
          label: 'New Employee'
        }
      }
    ]
  },
  {
    path: '/merchantFunds',
    name: 'merchant-funds',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name:'merchant-funds:adjustments-list' },
    meta: {
      label: 'Merchant Funds'
    },
    children: [
      {
        path: 'adjustments',
        name: 'merchant-funds:adjustments-list',
        component: () => import('../pages/merchantFunds/AdjustmentsList.vue'),
        meta: {
          label: 'Adjustments'
        }
      },
      {
        path: 'balanceAlerts',
        name: 'merchant-funds:balance-alerts',
        component: () => import('../pages/merchantFunds/BalanceAlerts/BalanceAlerts.vue'),
        meta: {
          label: 'Balance Alerts'
        }
      }
    ]
  },
  {
    path: '/auditTrail',
    name: 'audit-trail',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name:'merchant-funds:adjustments-list' },
    meta: {
      label: 'Audit Trail'
    },
    children: [
      {
        path: '',
        name: 'audit-trail:list',
        component: () => import('../pages/merchantFunds/AuditTrail.vue'),
        meta: {
          label: 'Audit Trail'
        }
      },
    ]
  },
  {
    path: '/merchants',
    name: 'merchant',
    component: () => import('../layouts/DefaultLayout.vue'),
    meta: {
      label: 'Merchants'
    },
    redirect: { name: 'merchant:list' },
    children: [
      {
        path: '',
        name: 'merchant:list',
        component: () => import('../pages/merchant/MerchantsList.vue'),
        meta: {
          label: 'Merchants'
        }
      },
      {
        path: 'edit/:id',
        name: 'merchant:edit',
        component: () => import('../pages/merchant/MerchantUpdate.vue'),
        meta: {
          label: 'Edit Merchant'
        }
      },
      {
        path: 'add',
        name: 'merchant:create',
        component: () => import('../pages/merchant/MerchantCreate.vue'),
        meta: {
          label: 'New Merchant'
        }
      },
      {
        path: 'balance',
        name: 'merchant:balances-list',
        component: () => import('../pages/merchant/MerchantBalancesList.vue'),
        meta: {
          label: 'Balances'
        }
      },
      {
        path: ':id',
        name: 'merchant:details',
        component: () => import('../pages/merchant/MerchantDetails.vue'),
        meta: {
          label: 'Merchant Details'
        }
      }
    ]
  },
  {
    path: '/tools',
    name: 'tools',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'tools:test-transaction-create' },
    meta: {
      label: 'Tools'
    },
    children: [
      {
        path: 'transactions/create',
        name: 'tools:test-transaction-create',
        component: () => import('../pages/tools/TestTransactionCreate.vue'),
        meta: {
          label: 'Create Transaction'
        }
      },
      {
        path: 'whitelisting',
        name: 'tools:whitelisting',
        component: () => import('../pages/tools/WhitelistingTesters.vue'),
        meta: {
          label: 'Whitelisting Testers'
        }
      }
    ]
  },
  {
    path: '/transactions',
    name: 'transaction',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'transaction:list' },
    meta: {
      label: 'Transactions'
    },
    children: [
      {
        path: '',
        name: 'transaction:list',
        component: () => import('../pages/transaction/TransactionsList.vue'),
        meta: {
          label: 'Transactions'
        }
      },
      {
        path: ':id',
        name: 'transaction:details',
        component: () => import('../pages/transaction/TransactionDetails.vue'),
        meta: {
          label: 'Transaction Details'
        }
      }
    ]
  },
  {
    path: '/reports',
    name: 'report',
    component: () => import('../layouts/DefaultLayout.vue'),
    meta: {
      label: 'Reports'
    },
    redirect: { name: 'report:daily-performance' },
    children: [
      {
        path: 'daily-performance',
        name: 'report:daily-performance',
        component: () => import('../pages/reports/DailyPerformanceReport.vue'),
        meta: {
          label: 'Daily Performance'
        }
      },
      {
        path: 'finance-payoro',
        name: 'report:finance-payoro',
        component: () => import('../pages/reports/FinanceReport.vue'),
        meta: {
          label: 'Finance Reporting'
        }
      }
    ]
  },
  {
    path: '/finance-tools',
    name: 'finance-tool',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'finance-tool:ledger-adjustment' },
    meta: {
      label: 'Finance Tools'
    },
    children: [
      {
        path: 'ledger-adjustment',
        name: 'finance-tool:ledger-adjustment',
        component: () => import('../pages/financeTools/LedgerAdjustment.vue'),
        meta: {
          label: 'Ledger Adjustment'
        }
      },
      {
        path: 'statements-upload',
        name: 'finance-tool:statements-upload',
        component: () => import('../pages/financeTools/StatementsUpload.vue'),
        meta: {
          label: 'Statements Upload'
        }
      }
    ]
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('../layouts/DefaultLayout.vue'),
    redirect: { name: 'not-found:page' },
    children: [
      {
        path: '',
        name: 'not-found:page',
        component: () => import('../pages/NotFound/NotFound.vue'),
        meta: {
          hideBreadcrumb: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  const token = getAccessToken()
  if (!token && to.name !== 'auth:login') {
    next({ name: 'auth:login' })
  } else if (token && to.name === 'auth:login') {
    next({ name: 'user:list' })
  }
  next()
})

export {
  router
}
