import { ACCESS_TOKEN_LS_KEY } from '../constants/auth.js';

export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN_LS_KEY)
  window.location = '/login'
}

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_LS_KEY)
}

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN_LS_KEY, token)
}
