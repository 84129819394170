<template>
  <div class="min-h-screen">
    <Toast />
    <RouterView />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>

</style>
